<template>
  <b-dropdown-form
    class="py-1 dropdown-filter-form-n"
  >
    <b-row>
      <b-col cols="10">
        <p>Filters</p>
      </b-col>
      <b-col cols="2">
        <feather-icon
          icon="XIcon"
          size="16"
          class="align-right"
          @click="$emit('emitHideFilter')"
        />
      </b-col>
    </b-row>
    <b-form-group
      label="Dates"
    >
      <b-input-group>
        <flat-pickr
          v-model="serviceDateFilterValue"
          class="form-control"
          placeholder="Select Date Range"
          :config="flatPickrConfig"
        />
        <b-input-group-append is-text>
          <feather-icon
            icon="CalendarIcon"
            class="cursor-pointer"
            data-toggle
            size="18"
          />
        </b-input-group-append>
      </b-input-group>
    </b-form-group>
    <hr class="dividerHR filter-divider">
    <b-form-group
      label="Request Type"
      @click.native.stop
    >
      <v-select
        v-model="requestTypeValue"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        label="name"
        multiple
        placeholder="Select an option"
        :options="requestTypeOptions"
        :selectable="option => !option.hasOwnProperty('category')"
        :clearable="false"
        :reduce="name => name._id"
      >
        <template #option="{ category, name, }">
          <div
            v-if="category"
            class="group"
          >
            {{ category }}
          </div>
          {{ name }}
        </template>
      </v-select>
    </b-form-group>
    <div>
      <hr class="dividerHR filter-divider">
      <b-form-group
        label="Assignee"
        @click.native.stop
      >
        <v-select
          v-model="assigneeValue"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="name"
          multiple
          placeholder="Select an option"
          :options="assigneeOptions"
          :clearable="false"
          :reduce="name => name._id"
        />
      </b-form-group>
    </div>
    <hr class="dividerHR filter-divider">
    <b-form-group label="Service venue">
      <b-form-checkbox
        v-for="option in venueOptions"
        :key="option.value"
        v-model="venueFilterValue"
        :value="option.value"
        name="flavour-3a"
      >
        {{ option.value }}
      </b-form-checkbox>
    </b-form-group>
    <hr class="dividerHR filter-divider">
    <b-form-group label="Offerings">
      <b-form-checkbox
        v-for="option in offeringOptions"
        :key="option.key"
        v-model="offeringFilterValue"
        :value="option.key"
        name="flavour-3a"
      >
        {{ option.value }}
      </b-form-checkbox>
    </b-form-group>
    <b-button
      variant="outline-primary"
      size="sm"
      class="mr-1"
      @click="clearFilter"
    >
      Clear All
    </b-button>
    <b-button
      variant="primary"
      size="sm"
      @click="applyFilter"
    >
      Apply Filter
    </b-button>
  </b-dropdown-form>
</template>

<script>
import {
  BDropdownForm, BFormGroup, BButton, BRow, BCol, BInputGroup, BInputGroupAppend, BFormCheckbox,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import '@core/scss/vue/libs/vue-flatpicker.scss'
import vSelect from 'vue-select'

export default {
  components: {
    BButton,
    BDropdownForm,
    BFormGroup,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    flatPickr,
    vSelect,
  },
  props: {
    venueFilter: {
      type: [Array, null],
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
    },
    offeringFilter: {
      type: [Array, null],
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
    },
    serviceDateFilter: {
      type: [String, null],
      default: null,
    },
    venueOptions: {
      type: Array,
      required: true,
    },
    requestType: {
      type: [Array, null],
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
    },
    requestTypeOptions: {
      type: Array,
      required: true,
    },
    assignee: {
      type: [Array, null],
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
    },
    assigneeOptions: {
      type: Array,
      required: true,
    },
    offeringOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      serviceDateFilterValue: this.serviceDateFilter,
      venueFilterValue: JSON.parse(JSON.stringify(this.venueFilter)) || [],
      offeringFilterValue: JSON.parse(JSON.stringify(this.offeringFilter)) || [],
      requestTypeValue: JSON.parse(JSON.stringify(this.requestType)) || [],
      assigneeValue: JSON.parse(JSON.stringify(this.assignee)) || [],
      flatPickrConfig: {
        wrap: true, mode: 'range', dateFormat: 'd/m/Y', enableTime: false, static: true, disableMobile: true,
      },
    }
  },
  watch: {
    serviceDateFilter: {
      immediate: true,
      handler(val) {
        this.serviceDateFilterValue = val
      },
    },
    venueFilter: {
      immediate: true,
      handler(val) {
        this.venueFilterValue = JSON.parse(JSON.stringify(val)) || []
      },
    },
    offeringFilter: {
      immediate: true,
      handler(val) {
        this.offeringFilterValue = JSON.parse(JSON.stringify(val)) || []
      },
    },
    requestType: {
      immediate: true,
      handler(val) {
        this.requestTypeValue = JSON.parse(JSON.stringify(val)) || []
      },
    },
    assignee: {
      immediate: true,
      handler(val) {
        this.assigneeValue = JSON.parse(JSON.stringify(val)) || []
      },
    },
  },
  methods: {
    showAgainDropdown() {
      if (this.serviceDateFilterValue) {
        this.$emit('emitShowFilter')
      }
    },
    applyFilter() {
      this.$emit('update:serviceDateFilter', this.serviceDateFilterValue)
      this.$emit('update:venueFilter', this.venueFilterValue)
      this.$emit('update:offeringFilter', this.offeringFilterValue)
      this.$emit('update:requestType', this.requestTypeValue)
      this.$emit('update:assignee', this.assigneeValue)

      this.$emit('emitHideFilter')
    },
    clearFilter() {
      this.serviceDateFilterValue = ''
      this.venueFilterValue = []
      this.offeringFilterValue = []
      this.requestTypeValue = []
      this.assigneeValue = []
      this.$emit('update:serviceDateFilter', this.serviceDateFilterValue)
      this.$emit('update:venueFilter', this.venueFilterValue)
      this.$emit('update:offeringFilter', this.offeringFilterValue)
      this.$emit('update:requestType', this.requestTypeValue)
      this.$emit('update:assignee', this.assigneeValue)

      this.$emit('emitHideFilter')
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
